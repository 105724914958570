<template>
<v-row>
  <v-col cols="12" md="12">
    <search-box 
        label="* Fornecedor"
        popup-label="Selecione um Fornecedor"
        :id.sync="value.idFornecedor"
        :nomeCompletoSemCnpj.sync="value.nomeCompletoFornecedorSemCnpj"
        :hidden-clear="true"
        :value="fornecedor"
        :loader-fn="loadeFornecedor"
        item-key="id"
        item-text="nomeCompletoSemCnpj"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Fornecedor encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="4">
    <lookup-dominio label="* Tipo Item"
                    :hidden-clear="true"
                    hint="Selecione o Tipo"
                    v-model="value.idTipoItemOferta"
                    :type="lookups.tipoItem"
                    />
  </v-col>
  <v-col cols="12" md="8">
    <search-box ref="comboOfertas"
        label="* Oferta"
        popup-label="Selecione uma Oferta"
        :id.sync="value.idOferta"
        :descricao.sync="value.descricaoOferta"
        :hidden-clear="true"
        :value="oferta"
        :loader-fn="loadeOferta"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Oferta encontrado
        </p>
      </template>
    </search-box>
  </v-col>
</v-row>
</template>

<script>
import {findAll as findAllOferta} from "../../../api/oferta";
import {findAll as findAllFornecedor} from "../../../api/fornecedor";
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";

export default {
  name: "OfertaForm",
  components: {SearchBox, LookupDominio},  
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    'value.idTipoItemOferta': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboOfertas.resetValues();
          this.$refs.comboOfertas.search();
        }
      }
    },
  }, 
  data() {
    return {
      lookups: {
        tipoItem: ItemDeDominio.TIPO_ITEM,
      },
    }
  }, 
  methods: {  
    loadeOferta(page, search) {
      return findAllOferta(page, {
        descricao: search,
        idTipoItem: this.value.idTipoItem === '' ? '--' : this.value.idTipoItem,
      })
    },
    loadeFornecedor(page, search) {
      return findAllFornecedor(page, {
        nome: search,
      })
    },
  },
  computed: {
    oferta(){
      if (this.value.idOferta == null) return null;
      return {
        id: this.value.idOferta,
        descricao: this.value.descricaoOferta,
      }      
    },      
    fornecedor(){
      if (this.value.idFornecedor == null) return null;
      return {
        id: this.value.idFornecedor,
        nomeCompletoSemCnpj: this.value.nomeCompletoFornecedorSemCnpj,
      }      
    },  
  }
}
</script>

<style scoped>

</style>